<template>
    <b-modal id="edit-visio" title="Modifier la vidéoconférence">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <!--Nom-->
            <b-form-group
                :state="states.name"
                class="mt-3 mb-0"
                invalid-feedback="Veuillez saisir un nom"
                label="Nom :"
                label-for="visioName"
            >
                <b-form-input
                    id="visioName"
                    v-model="selectedVisio.name"
                    :state="states.name"
                    placeholder="Nom de la vidéoconférence"
                    required
                    type="text"
                ></b-form-input>
            </b-form-group>

            <!--Date de début-->
            <label class="mt-3" for="visioStartDate">Date de début :</label>
            <b-form-datepicker
                id="visioStartDate"
                v-model="selectedVisio.startDate"
                :hide-header="true"
                :state="states.startDate"
                invalid-feedback="Veuillez saisir une date de début"
                placeholder="Date de début de la vidéoconférence"
                required
            ></b-form-datepicker>

            <!--Heure de début-->
            <label class="mt-3" for="visioStartTime">Heure de début :</label>
            <b-form-timepicker
                id="visioStartTime"
                v-model="selectedVisio.startTime"
                :hide-header="true"
                :state="states.startTime"
                invalid-feedback="Veuillez saisir une heure de début"
                locale="fr-FR"
                placeholder="Heure de début de la vidéoconférence"
                required
            ></b-form-timepicker>

            <!--Durée-->
            <b-form-group
                :state="states.duration"
                class="mt-3 mb-0"
                invalid-feedback="Veuillez saisir une durée positive"
                label="Durée :"
                label-for="visioDuration"
            >
                <b-input-group append="minutes">
                    <b-form-input
                        id="visioDuration"
                        v-model="selectedVisio.duration"
                        :state="states.duration"
                        placeholder="Durée de la vidéoconférence"
                        required
                        type="number"
                    ></b-form-input>
                </b-input-group>
            </b-form-group>

            <!--Compte dans le temps de formation-->
            <div class="d-flex align-items-center mt-3">
                <b-form-checkbox
                    id="visioTrainingTime"
                    v-model="selectedVisio.training_time"
                    class="m-0"
                    placeholder="Compte dans le temps de formation"
                    required
                    type="checkbox"
                ></b-form-checkbox>
                <label class="m-0" for="visioTrainingTime">Compte dans le temps de formation</label>
            </div>
        </form>

        <template #modal-footer>
            <b-button
                class="float-right"
                pill
                variant="outline-danger"
                @click="$bvModal.hide('edit-visio'), (sending = false), resetStates()"
            >
                Annuler
            </b-button>
            <b-button class="float-right" pill variant="outline-primary" @click="updateVisio()">
                <p v-if="!sending" class="m-0">Modifier</p>
                <b-spinner v-if="sending" label="Spinning" small variant="light"></b-spinner>
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import ApiService from '../../../core/services/api.service';

export default {
    name: 'UpdateVisio',
    props: {
        selectedVisio: {
            type: Object,
            required: true
        }
    },
    mounted() {
        this.user = this.$store.getters.currentUser;
    },
    data() {
        return {
            states: {
                name: null,
                startDate: null,
                startTime: null,
                duration: null
            },
            user: null,
            sending: false
        };
    },
    methods: {
        checkFormValidity() {
            this.resetStates();
            const valid = this.$refs.form.checkValidity();

            this.states.name = this.selectedVisio.name !== '';
            this.states.startDate = this.selectedVisio.startDate !== '';
            this.states.startTime = this.selectedVisio.startTime !== '';
            this.states.duration = this.selectedVisio.duration !== '' && this.selectedVisio.duration >= 0;

            return Object.values(this.states).every((state) => state) && valid;
        },
        resetStates() {
            this.states.name = null;
            this.states.startDate = null;
            this.states.startTime = null;
            this.states.duration = null;
        },
        updateVisio() {
            this.sending = true;
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                this.sending = false;
                return;
            }
            this.resetStates();
            //Call API
            let data = {
                id: this.selectedVisio.id,
                name: this.selectedVisio.name,
                start: this.selectedVisio.startDate + ' ' + this.selectedVisio.startTime,
                duration: this.selectedVisio.duration,
                training_time: this.selectedVisio.training_time,
                tuteur_id: this.user.id
            };
            ApiService.put('academy/videoconference/' + data.id, data)
                .then((result) => {
                    this.$bvToast.toast(result.data.email != null ? result.data.email : 'La vidéoconférence a été modifiée avec succès', {
                        title: `La vidéoconférence a été modifiée avec succès`,
                        variant: 'success',
                        solid: true
                    });
                    this.$emit('reloadVisios');
                    this.sending = false;
                    this.$nextTick(() => {
                        this.$bvModal.hide('edit-visio');
                    });
                })
                .catch(() => {
                    this.sending = false;
                    this.$bvToast.toast('Une erreur est survenue lors de la modification de cette vidéoconférence', {
                        title: `modification impossibile`,
                        variant: 'danger',
                        solid: true
                    });
                });
        }
    }
};
</script>
